import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Dexron() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="DEXRON-III-1L.webp"
            ProductImage2="DEXRON-III-1L.webp"
            ProductImage3="DEXRON-III-1L.webp"
            ProductCategory="Diesel Engine"
            ProductName="<span> DEXRON III  </span> Automatic Plus Transmission fluid"
            ProductDatail="This ATF Automatic Transmission Fluid has a high viscosity index to ensure great fluidity on engine start and guaranteed component protection at high temperatures."
            ProductSize1="1L"

            // PRODUCT INFORMATION

            productInfo1= "DEXRON III ATF is a premium quality automatic transmission fluid designed to meet or exceed the performance requirementsIt provides excellent resistance to oxidation and thermal breakdown, ensuring long-lasting performance even under extreme conditions"

            // HOW TO USE

            textuse1= "Ensure that the vehicle is on level ground before adding automatic transmission oil."
            textuse2= "Check the owner's manual for the amount of automatic transmission oil."
            textuse3= "Remove the dipstick or fill port cap and use a funnel to pour the automatic transmission oil slowly into the dipstick or fill port until it reaches the recommended level on the dipstick."
            textuse4= "Start the engine and allow it to run for a few minutes to circulate the automatic transmission oil."
            textuse5= "Turn off the engine and wait a few minutes for the automatic transmission oil to settle."
            textuse6= "Check the level of the automatic transmission oil using the dipstick or fill port, and add more if necessary to bring it to the recommended level."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."
 // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.836"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="7.32"

            
            R4td1="Kinematic viscosity @40°C, cSt "
            R4td2="ASTM D 4052-16"
            R4td3="35.18"

            R7td1="Viscosity Index    "
            R7td2="ASTM D 2270   "
            R7td3="180"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -45"

            R6td1="Colour"
            R6td2="Visual "
            R6td3="Red"

            
            // RELATED PRODUCTS

            SliderProductCategory1="Gasoline Engine"
             SliderProductCategory2="Gasoline Engine"
             SliderProductCategory3="Gasoline/Diesel Engine"
             SliderProductCategory4="Gasoline/Diesel Engine"
 
             SliderProductImage1="0W20-5L.webp"
             SliderProductImage2="5w30.webp"
             SliderProductImage3="10W40-5L.webp"
             SliderProductImage4="15W40-5L..webp"
 
             SliderProductName1="<span>0W-20</span> Hybrid Synthetic "
             SliderProductName2="<span> 5W-30 </span>Celeritas plus Fully synthetic"
             SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
             SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"
 
             SliderProductApi1="API SP"
             SliderProductApi2="API SP"
             SliderProductApi3="API SL/CF"
             SliderProductApi4="API SL/CI"
                                   
             SliderProductDatail1="Recommended for modern petrol enginesIt provides outstanding.."
             SliderProductDatail2="This oil reduces wear, enhances fuel efficiency, and lowers CO2..."
             SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
             SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."
 
             SliderRouteLink1="/0w20"
             SliderRouteLink2="/5w30"
             SliderRouteLink3="/10w40"
             SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="gmail.svg" Src2="facebook.svg" Src3="instagram.svg" />        
    </div>
  )
}
