import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil0W16() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="0W16-1L.png"
            ProductCategory="Gasoline Engine"
            ProductName="<span>0W-16</span> Full Synthetic Platinum "
            ProductApi="API SP"
            ProductDatail="Recommended for modern petrol enginesIt provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
            ProductSize1="1L"

            // PRODUCT INFORMATION

            productInfo1= "Our fully synthetic lubricant was created with the specific purpose of extending the life of your engine and maintaining the effectiveness of pollution controls while offering outstanding engine cleaning, wear protection, and durability. Additionally, this lubricant offers significant fuel savings and lower CO2 emissions because to its ultra-low 0W16 viscosity grade. "

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PURE OIL car engine oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust it if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // Safety Info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs"
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist"
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."
            
            // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.836"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="8.60"

            R4td1="Viscosity Index    "
            R4td2="ASTM D 2270   "
            R4td3="170"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -42"

            R6td1="Total Base Number, mg KOH/g"
            R6td2="ASTM D 2896 "
            R6td3="8.35"

            R7td1="Apparent Viscosity @ -35DC, cP"
            R7td2="ASTM D 5293 "
            R7td3="5950"
            
            // RELATED PRODUCTS

            SliderProductCategory1="Gasoline Engine"
            SliderProductCategory2="Gasoline Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1="5W20-5L.webp"
            SliderProductImage2="5w30.webp"
            SliderProductImage3="10W40-5L.webp"
            SliderProductImage4="15W40-5L..webp"

            SliderProductName1="<span>5W-20 </span> Celeritas plus Fully synthetic"
            SliderProductName2="<span> 5W-30 </span>Celeritas plus Fully synthetic"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1="API SP"
            SliderProductApi2="API SP"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="This oil significantly reduces wear and ensures fuel savings and.."
            SliderProductDatail2="This oil reduces wear, enhances fuel efficiency, and lowers CO2..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/5w20"
            SliderRouteLink2="/5w30"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="gmail.svg" Src2="facebook.svg" Src3="instagram.svg" />        
    </div>
  )
}
