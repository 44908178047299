import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Sae85W90() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail sae">
          <SingleProduct
            ProductImage=" Gear oil-Sae140-1L.png"
            ProductCategory="Gear Oils"
            ProductName="<span> SAE 85W-90  </span>"
            ProductDatail="SAE 85W-90 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a ‘limited-slip’ mechanism providing superior protect against wear and rust."
            // ProductSize1="1L"
            ProductSize1="1L"
            RouteLink="/sae140"

            // PRODUCT INFORMATION

            productInfo1= "Our SAE 140 gear oil provides a special friction additive that imparts to this oil the necessary properties to be used in all hypoid rear axles with a limited-slip mechanismIt provides good protection against oxidation, corrosion, and rust and helps extend gear life."

            // HOW TO USE

            textuse1= "Check the owner's manual for the correct amount of CVT oil."
            textuse2= "Ensure that the vehicle is on level ground before adding CVT oil."
            textuse3="Use a funnel to pour the gear oil slowly into the fill hole until it reaches the level of the hole."
            textuse4="Start the engine and allow it to run for a few minutes to circulate the CVT oil."
            textuse5="Check the level of the CVT oil using the dipstick or fill port, and add more if necessary to bring it to the recommended level."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."
   
            // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.895"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="31.2"

            R4td1="Viscosity Index    "
            R4td2="ASTM D 2270   "
            R4td3="96"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -15"

            
            // RELATED PRODUCTS

            SliderProductCategory1="Gear Oils"
            SliderProductCategory2="Gasoline Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1=" GEAR OIL-1L.webp"
            SliderProductImage2=" 5w30.webp"
            SliderProductImage3=" 10W40-5L.webp"
            SliderProductImage4=" 15W40-5L..webp"

            SliderProductName1="<span> SAE 90  </span>"
            SliderProductName2="<span> 5W-30 </span>Celeritas plus Fully synthetic"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            
            SliderProductApi2="API SP"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="SAE 90 gear oil is a premium quality lubricant designed for use.."
            SliderProductDatail2="This oil reduces wear, enhances fuel efficiency, and lowers CO2..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/sae90"
            SliderRouteLink2="/5w30"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1=" gmail.svg" Src2=" facebook.svg" Src3=" instagram.svg" />        
    </div>
  )
}
