import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil5W30() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
             ProductImage="5W20-1L.png"
          ProductImage2="5w30.webp"
            ProductCategory="Gasoline Engine"
            ProductName="<span> 5W-30 </span>Celeritas plus Fully synthetic"
            ProductApi="API SP"
            ProductDatail="This oil reduces wear, enhances fuel efficiency, and lowers CO2 emissions while preventing soot and depositsIdeal for hybrid cars."
            
            ProductSize1="1L"
            ProductSize2="5L"

            

            // PRODUCT INFORMATION

            productInfo1= "This advanced oil, with its low viscosity and exceptional fluidity, plays a pivotal role in reducing wear and promoting fuel efficiency, resulting in lower CO2 emissionsWith its state-of-the-art formulation, this oil ensures an effortless cold start, minimizes wear, and enhances fuel economy"

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // safety info

            safetyInfo1= " Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Pour the PUREOIL Car Engine Oil into the engine."
            safetyInfo5="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo6="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses"

            // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.8394"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="11.4"

            R4td1="Viscosity Index    "
            R4td2="ASTM D 2270   "
            R4td3="164"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -39"

            R6td1="Total Base Number, mg KOH/g"
            R6td2="ASTM D 2896 "
            R6td3="8.47"

            R7td1="Apparent Viscosity @ -35DC, cP"
            R7td2="ASTM D 5293 "
            R7td3="5284"
            
            // RELATED PRODUCTS

           SliderProductCategory1="Gasoline Engine"
           SliderProductCategory2="Gasoline Engine"
           SliderProductCategory3="Gasoline/Diesel Engine"
           SliderProductCategory4="Gasoline/Diesel Engine"

           SliderProductImage1="0W20-5L.webp"
           SliderProductImage2="5W20-5L.webp"
           SliderProductImage3="10W40-5L.webp"
           SliderProductImage4="15W40-5L..webp"

           SliderProductName1="<span>0W-20</span> Hybrid Synthetic "
           SliderProductName2="<span>5W-20 </span> Celeritas plus Fully synthetic"
           SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
           SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

           SliderProductApi1="API SP"
           SliderProductApi2="API SP"
           SliderProductApi3="API SL/CF"
           SliderProductApi4="API SL/CI"
                                 
           SliderProductDatail1="Recommended for modern petrol enginesIt provides outstanding.."
           SliderProductDatail2="This oil significantly reduces wear and ensures fuel savings..."
           SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
           SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

           SliderRouteLink1="/0w20"
           SliderRouteLink2="/5w20"
           SliderRouteLink3="/10w40"
           SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="gmail.svg" Src2="facebook.svg" Src3="instagram.svg" />        
    </div>
  )
}
