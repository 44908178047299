import React from 'react';
import "./Preloader.css"
const Preloader = () => {
  return (
    <div className="preloader">
      <img src="preloader.gif" alt="" />
    </div>
  );
};

export default Preloader;
