import React, { useState } from "react";
import "../../Styles/ProductPage.css";
import Navbar from "../../Components/Navbar/Navbar";

import Text from "../../Components/Text/Text";
import Image from "../../Components/Image/Image";
import EachProduct from "../../Components/EachProduct/EachProduct";
import Footer from "../../Components/Footer/Footer";
import ProdtuctPageSingleItem from "../../Components/ProdtuctPageSingleItem/ProdtuctPageSingleItem";

export default function ProductPage() {
  const [isChecked, setIsChecked] = useState(false);

  const handleGasoline = () => {
    setIsChecked((prevChecked) => !prevChecked);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);
    setisCheckedHydraulic(false);
    
  };

  // Diesel useState

  const [isCheckedDiesel, setIsCheckedDiesel] = useState(false);

  const handleDiesel = () => {
    setIsChecked(false);
    setIsCheckedDiesel((prevChecked) => !prevChecked);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);
  };

  // Gear useState

  const [isCheckedGear, setIsCheckedGear] = useState(false);

  const handleGear = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear((prevChecked) => !prevChecked);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);
    if (isCheckedGear) {
      setIsCheckedOneL(false);
    }
    if (!isCheckedGear) {
      setIsCheckedOneL(true);
    }
  };

  // Transmission useState

  const [isCheckedTransmission, setIsCheckedTransmission] = useState(false);

  const handleTransmission  = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false); 
    setIsCheckedGear(false);
    setIsCheckedTransmission((prevChecked) => !prevChecked);
    setIsCheckedFlush(false);
    setisCheckedHydraulic(false)
    if (isCheckedTransmission) {
      setIsCheckedOneL(false);
    }
    if (!isCheckedTransmission) {
      setIsCheckedOneL(true);
    }
  };

  //Hydraulic oil isCheckedHydraulic
  const [isCheckedHydraulic, setisCheckedHydraulic] = useState(false);

  const handleHydraulic = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setisCheckedHydraulic((prevChecked) => !prevChecked);
    setIsCheckedFlush(false);
    // if (isCheckedHydraulic) {
    //   setIsCheckedOneL(false);
    // }
    // if (!isCheckedHydraulic) {
    //   setIsCheckedOneL(false);
    // }
  };


  // English Flush useState

  const [isCheckedFlush, setIsCheckedFlush] = useState(false);

  const handleFlush = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush((prevChecked) => !prevChecked);

    if (isCheckedFlush) {
      setIsCheckedFourL(false);
    }
    if (!isCheckedFlush) {
      setIsCheckedFourL(true);
    }
  };

  // ONE Letter useState

  const [isCheckedOneL, setIsCheckedOneL] = useState(false);

  const handleOneL = () => {
    setIsCheckedOneL((prevChecked) => !prevChecked);
    setIsCheckedFourL(false);
    setIsCheckedFiveL(false);
  };

  // Four Letter useState

  const [isCheckedFourL, setIsCheckedFourL] = useState(false);

  const handleFourL = () => {
    setIsCheckedOneL(false);
    setIsCheckedFourL((prevChecked) => !prevChecked);
    setIsCheckedFiveL(false);
  };

  // Five Letter useState

  const [isCheckedFiveL, setIsCheckedFiveL] = useState(false);

  const handleFiveL = (event) => {
    setIsCheckedOneL(false);
    setIsCheckedFourL(false);
    setIsCheckedFiveL((prevChecked) => !prevChecked);
  };

  return (
    <div className="ProductPage">
      <div className="ProductPAgeNAvbar d-flex justify-content-center">
        <Navbar />
      </div>

      {/************************* 
         * SECTION 2
        Best Product  Section
       * ***************************** */}

      <div className="bestProductClass">
        <div className="row m-0 p-0">
          <div className="col-md-5 d-flex align-items-center">
            <div className="leftSec mb-5">
              <div className="BestProductHead">
                <Text
                  text="best-in-class <br>
                  lubricant <span> products </span>"
                  Margin="0px 0px 25px 0px"
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="RightSec">
              <Image Src=" bestProduct.webp" />
            </div>
          </div>
        </div>
      </div>

      {/************************* 
         * SECTION 3
        Side Bar
       * ***************************** */}
      <div className="ProductDetailList d-flex">
        <div className="FilerSideBar">
          <Text
            text=" FILTER BY"
            Color="#273889"
            FontSize="2rem"
            FontWeight="600"
            Margin="0px 0px 45px 15px"
          />
          <div className="SizeCatFilter">
            <div className="SizeFilter">
              <Text
                text="Size"
                Color="black"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="0px 0px 25px 0px"
              />
              <div className="OneLT ">
                <input
                  checked={isCheckedOneL}
                  onChange={handleOneL}
                  disabled={isCheckedFlush || isCheckedHydraulic}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> 1 LT </label>
              </div>
              <div className="OneLT">
                <input
                  checked={isCheckedFourL}
                  onChange={handleFourL}
                  disabled={isCheckedGear || isCheckedTransmission || isCheckedHydraulic}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> 4 LT </label>
              </div>
              <div className="OneLT">
                <input
                  checked={isCheckedFiveL}
                  onChange={handleFiveL}
                  disabled={
                    isCheckedGear || isCheckedTransmission || isCheckedFlush || isCheckedHydraulic
                  }
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> 5 LT </label>
              </div>
            </div>

            <div className="SizeFilter">
              <Text
                text="Categories "
                Color="black"
                FontSize="1.8rem"
                FontWeight="600"
                Margin="0px 0px 25px 0px"
              />

              {/* Gasoline check box */}

              <div className="OneLT">
                <input
                  checked={isChecked}
                  onChange={handleGasoline}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> Gasoline Engine </label>
              </div>

              {/* Diesel check box */}

              <div className="OneLT">
                <input
                  checked={isCheckedDiesel}
                  onChange={handleDiesel}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1">Diesel Engine</label>
              </div>

              {/* Gear check box */}

              <div className="OneLT">
                <input
                  checked={isCheckedGear}
                  onChange={handleGear}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> Gear Oils </label>
              </div>

              {/* Transmission check box */}

              <div className="OneLT">
                <input
                  checked={isCheckedTransmission}
                  onChange={handleTransmission}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> Transmission Oils </label>
              </div>

              {/* Hydraulic check box */}

              <div className="OneLT">
                <input
                  checked={isCheckedHydraulic}
                  onChange={handleHydraulic} 
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> Hydraulic Oils </label>
              </div>


              {/* Engine Flush check box */}

              <div className="OneLT">
                <input
                  checked={isCheckedFlush}
                  onChange={handleFlush}
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                  class="abc"
                />
                <label for="vehicle1"> Engine Flush </label>
              </div>
            </div>
          </div>
        </div>

        {/* GASOLINE ENGINE OIL */}

        <div className="ProductsList">
          {isChecked && (
            <div className="gasoline" id="gasoline">
              <div className="ProductHeding">
                <Text
                  text="Gasoline Engine"
                  Color="#283989"
                  FontSize="2rem"
                  FontWeight="600"
                />
              </div>

              {isCheckedOneL && (
                <>
                <div className="ProductItems">
                    <div className="ProductItemsEach" id="oneL">

                      {/* PRODUCT   0W20 */}

                    <ProdtuctPageSingleItem
                      ProductImage2="5W20-5L.webp"
                      ProductImage="5W20-1L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>5W-20 </span> Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil significantly reduces wear and ensures fuel savings and lower CO2 emissionsIt reduces soot and depositsSuitable for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w20"
                    />
                    </div>

                    <div className="ProductItemsEach" id="oneL">

                      {/* PRODUCT   5W30 */}

                    <ProdtuctPageSingleItem
                      ProductImage="5w30-1L.png"
                      ProductImage2="5w30.webp"
                      ProductImage3="5w30.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span> 5W-30 </span>Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil reduces wear, enhances fuel efficiency, and lowers CO2 emissions while preventing soot and depositsIdeal for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w30"
                    />
                    </div>

                    {/* <div className="ProductItemsEach" id="oneL">
                    <ProdtuctPageSingleItem
                      ProductImage="0W20-1L.png"
                      ProductImage2="0W20-5L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Full Synthetic Race "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/0w20_full_syn"
                    />
                    </div> */}

                    

                    <div className="ProductItemsEach" id="oneL">
                      {/* PRODUCT 1  0W20 */}
                    <ProdtuctPageSingleItem
                      ProductImage="0W16-1L.png"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-16</span> Full Synthetic Platinum "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      RouteLink="/0w16"
                    />
                    </div>

                    


                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">
                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-1L.png"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">
                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">
                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isCheckedFourL && (
                <>
                <div className="ProductItemsEach">
                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT  10W30 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   20W50 */}

                        <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isCheckedFiveL && (
                <>
                  <div className="ProductItems">
                    {/* <div className="ProductItemsEach" id="oneL">

                    <ProdtuctPageSingleItem
                      ProductImage="0W20-5L.webp"
                      ProductImage2="0W20-5L.webp"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Hybrid Synthetic "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol enginesIt provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="5L"
                      RouteLink="/0w20"
                    />
                    </div> */}

                    <div className="ProductItemsEach">

                      {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                    </div>

                    <div className="ProductItemsEach">

                      {/* PRODUCT   20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                    </div>
                  </div>
                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W30 */}

                      <ProdtuctPageSingleItem
                         ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   20W50 */}

                        <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* IF SIZE IS NOT SELECTED THEN ALL PRODUCt of Gasoline WILL SHOW */}
              {!isCheckedOneL && !isCheckedFourL && !isCheckedFiveL && (
                <>
                  <div className="ProductItems">
                    {/* <div className="ProductItemsEach" id="oneL">

                    <ProdtuctPageSingleItem
                      ProductImage="0W20-5L.webp"
                      ProductImage2="0W20-5L.webp"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Hybrid Synthetic "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol enginesIt provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="5L"
                      RouteLink="/0w20"
                    />
                    </div> */}

                    {/* <div className="ProductItemsEach" id="oneL">
                    <ProdtuctPageSingleItem
                      ProductImage="0W20-1L.png"
                      ProductImage2="0W20-5L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Full Synthetic Race "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/0w20_full_syn"
                    />
                    </div> */}

                    <div className="ProductItemsEach" id="oneL">
                      {/* PRODUCT 1  0W20 */}
                    <ProdtuctPageSingleItem
                      ProductImage="0W16-1L.png"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-16</span> Full Synthetic Platinum "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      RouteLink="/0w16"
                    />
                    </div>

                    <div className="ProductItemsEach">

                       {/* PRODUCT   5W20 */}

                    <ProdtuctPageSingleItem
                      ProductImage2="5W20-5L.webp"
                      ProductImage="5W20-1L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>5W-20 </span> Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil significantly reduces wear and ensures fuel savings and lower CO2 emissionsIt reduces soot and depositsSuitable for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w20"
                    />
                    </div>

                    <div className="ProductItemsEach">

                      {/* PRODUCT   5W30 */}

                    <ProdtuctPageSingleItem
                      ProductImage="5w30-1L.png"
                      ProductImage2="5w30.webp"
                      ProductImage3="5w30.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span> 5W-30 </span>Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil reduces wear, enhances fuel efficiency, and lowers CO2 emissions while preventing soot and depositsIdeal for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w30"
                    />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT  10W30 */}

                      <ProdtuctPageSingleItem
                         ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                         {/* PRODUCT   15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Diesel ENGINE OIL */}

          {isCheckedDiesel && (
            <>
              <div className="diesel" id="diesel">
                <div className="ProductHeding">
                  <Text
                    text="Diesel Engine"
                    Color="#283989"
                    FontSize="2rem"
                    FontWeight="600"
                  />
                </div>
              </div>

              {isCheckedOneL && (
                <>
                  <div className="ProductItems">
                    <div className="textnoitem">
                      <Text
                        text="No product of the diesel category is available in four litter"
                        Color="black"
                      />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                         {/* PRODUCT   20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isCheckedFourL && (
                <>
                  <div className="ProductItems">
                    <div className="textnoitem">
                      <Text
                        text="No product of the diesel category is available in four litter"
                        Color="black"
                      />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT  10W30 */}

                      <ProdtuctPageSingleItem
                         ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                         {/* PRODUCT  20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isCheckedFiveL && (
                <>
                  <div className="ProductItems" id="dieselcheck">
                    <div className="ProductItemsEach">
                      
                      {/* PRODUCT   HD 50 */}

                      <ProdtuctPageSingleItem
                        ProductImage=" HD50-5L.webp"
                        ProductImage2=" HD50-20L.png"
                        ProductImage3=" HD50-5L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> HD 50 </span> Fleet X"
                        ProductApi="API CF"
                        ProductDatail="HD 50 is performance heavy-duty engine oil, primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment."
                        ProductSize1="5L"
                        ProductSize2="20L"
                        RouteLink="/hd50"
                      />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                         {/* PRODUCT   10W30 */}

                      <ProdtuctPageSingleItem
                         ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT   20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!isCheckedOneL && !isCheckedFourL && !isCheckedFiveL && (
                <>
                  <div className="ProductItems" id="dieselcheck">

                    <div className="ProductItemsEach">

                      {/* PRODUCT   HD 50 */}

                      <ProdtuctPageSingleItem
                        ProductImage=" HD50-5L.webp"
                        ProductImage2="HD50-20L.png"
                        ProductImage3=" HD50-5L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> HD 50 </span> Fleet X"
                        ProductApi="API CF"
                        ProductDatail="HD 50 is performance heavy-duty engine oil, primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment."
                        ProductSize1="5L"
                        ProductSize2="20L"
                        RouteLink="/hd50"
                      />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding">
                      <Text
                        text="Compatabel for both Diesel/Gasoline"
                        Color="#283989"
                        FontSize="2rem"
                        FontWeight="600"
                      />
                    </div>
                    <div className="ProductItems">
                      <div className="ProductItemsEach">

                        {/* PRODUCT   10W30 */}

                      <ProdtuctPageSingleItem
                         ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct
                          RouteLink="/10w40"
                          Src=" 10W40-5L.webp"
                          ProductCategory="Gasoline/Diesel Engine"
                          ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                          ProductApi="API SL/CF"
                          ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oilsThanks to its optimum viscosity index it can be used all year round.</span> "
                        />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                      </div>

                      <div className="ProductItemsEach">

                        {/* PRODUCT  20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {/************* ***********************8
           * *********** Gear OIL********************
           * *********************************/}

          {isCheckedGear && (
            <div className="Gear" id="gearoil">
              <div className="ProductHeding">
                <Text
                  text="Gear Oils"
                  Color="#283989"
                  FontSize="2rem"
                  FontWeight="600"
                />
              </div>
              <div className="ProductItems ">
                <div className="ProductItemsEach sae90">

                  {/* PRODUCT 11  SAE 90  */}

                  <ProdtuctPageSingleItem
                        ProductImage=" GEAR OIL-1L.webp"
                        ProductImage2="GEAR OIL-18L.png"
                        ProductCategory="Gear Oils"
                        ProductName="<span> SAE 90  </span>"
                        ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a 'limited-slip' mechanism providing superior protection against wear and rust."
                        ProductSize1="1L"
                        ProductSize2="18L"
                        RouteLink="/sae90"
                      />
                </div>
                <div className="ProductItemsEach sae90">

                  {/* PRODUCT  SAE 90  */}

                  <ProdtuctPageSingleItem
                        ProductImage=" Gear oil-Sae140-18L.png"
                        ProductCategory="Gear Oils"
                        ProductName="<span> SAE 140  </span>"
                        ProductDatail="SAE 140 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a 'limited-slip' mechanism providing superior protection against wear and rust."
                        // ProductSize1="1L"
                        ProductSize1="18L"
                        RouteLink="/sae140"
                      />
                </div>
                <div className="ProductItemsEach">

                  {/* PRODUCT   ENGINE FLUSH  */}

                      <ProdtuctPageSingleItem
                        ProductImage=" CVT-1L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> CVT Fluid  </span> Full Synthetic"
                        ProductDatail="Full synthetic fluid designed for most CVT (Continuously Variable Transmission) gearboxes with steel push-belts or chainsIt offers superior anti-wear protection and stable friction characteristics."
                        ProductSize1="1L"
                        RouteLink="/cvtoil"
                      />
                </div>
              </div>
            </div>
          )}

          {/* Transmission OIL */}

          {isCheckedTransmission && (
            <div className="Gear" id="Transmissionoil">
              <div className="ProductHeding">
                <Text
                  text="Transmission Oils"
                  Color="#283989"
                  FontSize="2rem"
                  FontWeight="600"
                />
              </div>
              <div className="ProductItems">
                <div className="ProductItemsEach">

                   {/* PRODUCT   DEXRON III  */}

                      <ProdtuctPageSingleItem
                        ProductImage="DEXRON-III-1L.webp"
                        ProductImage2="DEXRON-III-1L.webp"
                        ProductImage3="DEXRON-III-1L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> DEXRON III  </span> Automatic Plus Transmission fluid"
                        ProductDatail="This ATF Automatic Transmission Fluid has a high viscosity index to ensure great fluidity on engine start and guaranteed component protection at high temperatures."
                        ProductSize1="1L"
                        RouteLink="/dexron"
                      />
                </div>
              </div>
            </div>
          )}

          {/* Engine Flush Oil */}

          {isCheckedFlush && (
            <div className="Gear" id="Flushoil">
              <div className="ProductHeding">
                <Text
                  text="Engine Flush"
                  Color="#283989"
                  FontSize="2rem"
                  FontWeight="600"
                />
              </div>
              <div className="ProductItems">
                <div className="ProductItemsEach">

                   {/* PRODUCT   ENGINE FLUSH  */}

                      <ProdtuctPageSingleItem
                        ProductImage="ENGINE FLUSH-4L.webp"
                        ProductImage2="ENGINE FLUSH-4L.webp"
                        ProductImage3="ENGINE FLUSH-4L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> ENGINE FLUSH </span>"
                        ProductDatail="Engine Flush is a specially formulated combination of additives and a carrier fluidIn combination with the carrier fluid, the highly effective detergent and dispersant additives dissolve sludge and lacquer formers."
                        ProductSize1="4L"
                        RouteLink="/enginflush"
                      />
                </div>
              </div>
            </div>
          )}

          {/* Hydraulic OIL */}

          {isCheckedHydraulic && (
            <div className="Gear" id="Hydraulicoil">
                  <div className="ProductHeding">
                    <Text
                      text="Hydraulic Oils"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">

                      {/* PRODUCT 13  DEXRON III  */}

                      <ProdtuctPageSingleItem
                        ProductImage="HydraulicHy68.png"
                        ProductCategory="Hydraulic Oils"
                        ProductName="<span> HY 68  </span> Fleet X"
                        ProductDatail="Hydraulic 68 Oils is a premium quality lubricant designed for use in differentials, which are equipped with a ‘limited-slip’ mechanism providing superior protect against wear and rust."
                        ProductSize1="18L"
                        RouteLink="/hy68"
                      />
                    </div>
                  </div>
                </div>
          )}
          



          {/* show all product if no check box is checked */}

          {!isChecked &&
            !isCheckedDiesel &&
            !isCheckedGear &&
            !isCheckedTransmission &&
            !isCheckedFlush &&
            !isCheckedHydraulic && (
              <>
                {/* GASOLINE OIL IF NO CHECK BOX IS SELECTED */}
                <div className="gasoline" id="gasoline">
                  <div className="ProductHeding">
                    <Text
                      text="Gasoline Engine"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>

                  <div className="ProductItems">
                    {/* <ProdtuctPageSingleItem
                      ProductImage="0W20-5L.webp"
                      ProductImage2="0W20-5L.webp"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Hybrid Synthetic "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol enginesIt provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="5L"
                      RouteLink="/0w20"
                    /> */}

                    {/* PRODUCT 1  0W20 */}
                    {/* <ProdtuctPageSingleItem
                      ProductImage="0W20-1L.png"
                      ProductImage2="0W20-5L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-20</span> Full Synthetic Race "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/0w20_full_syn"
                    /> */}

                    {/* PRODUCT 1  0W20 */}
                    <ProdtuctPageSingleItem
                      ProductImage="0W16-1L.png"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>0W-16</span> Full Synthetic Platinum "
                      ProductApi="API SP"
                      ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness, wear protection, strong durability, and Fuel Economy."
                      ProductSize1="1L"
                      RouteLink="/0w16"
                    />


                    {/* PRODUCT 2  5W20 */}

                    <ProdtuctPageSingleItem
                      ProductImage2="5W20-5L.webp"
                      ProductImage="5W20-1L.png"
                      ProductImage3="20W50-5L.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span>5W-20 </span> Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil significantly reduces wear and ensures fuel savings and lower CO2 emissionsIt reduces soot and depositsSuitable for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w20"
                    />

                    {/* PRODUCT 3  5W30 */}

                    <ProdtuctPageSingleItem
                      ProductImage="5w30-1L.png"
                      ProductImage2="5w30.webp"
                      ProductImage3="5w30.webp"
                      ProductCategory="Gasoline Engine"
                      ProductName="<span> 5W-30 </span>Celeritas plus Fully synthetic"
                      ProductApi="API SP"
                      ProductDatail="This oil reduces wear, enhances fuel efficiency, and lowers CO2 emissions while preventing soot and depositsIdeal for hybrid cars."
                      ProductSize1="1L"
                      ProductSize2="5L"
                      RouteLink="/5w30"
                    />
                  </div>
                </div>

                {/* Diesel ENGINE OIL IF NO CHECK BOX IS SELECTED */}

                <div className="diesel" id="diesel">
                  <div className="ProductHeding">
                    <Text
                      text="Diesel Engine"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">

                      {/* PRODUCT 4  HD 50 */}

                      <ProdtuctPageSingleItem
                        ProductImage=" HD50-5L.webp"
                        ProductImage2=" HD50-20L.png"
                        // ProductImage3=" HD50-5L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> HD 50 </span> Fleet X"
                        ProductApi="API CF"
                        ProductDatail="HD 50 is performance heavy-duty engine oil, primarily intended for use in gasoline automotive engines of passenger cars, commercial vehicles and farm equipment."
                        ProductSize1="5L"
                        ProductSize2="20L"
                        RouteLink="/hd50"
                      />
                    </div>
                  </div>
                </div>

                {/* Compatable for both Diesel and gasoline */}

                <div className="Compatable">
                  <div className="ProductHeding">
                    <Text
                      text="Compatabel for both Diesel/Gasoline"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">
                      {/* PRODUCT 5  10W30 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W30-1L.png"
                        ProductImage2="10W30-4L.png"
                        ProductImage3="10W30-5L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W-30 </span> GOLDEN POWER"
                        ProductApi="API SL/CF"
                        ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w30"
                      />
                    </div>

                    <div className="ProductItemsEach">
                    
                      {/* PRODUCT 6  10W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="10W40-1L.png"
                        ProductImage2="10W40-5L.webp"
                        ProductImage3="10W40-5L.webp"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
                        ProductApi="API SL/CF"
                        ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        RouteLink="/10w40"
                      />
                    </div>

                    <div className="ProductItemsEach">
                      {/* PRODUCT 7  15W40 */}

                      <ProdtuctPageSingleItem
                        ProductImage="15W40-1L.png"
                        ProductImage2="15W40-4L.png"
                        ProductImage3="15W40-5L.png"
                        ProductImage4="15W40-20L.png"
                        ProductCategory="Gasoline Engine"
                        ProductName="<span> 15W40 </span> Speed X Semi-Synthetic"
                        ProductApi="API SL/CI-4"
                        ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable performance in a broad range of heavy-duty applications"
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/15w40"
                      />
                    </div>

                    <div className="ProductItemsEach">
                      {/* PRODUCT 8  20W50 */}

                      <ProdtuctPageSingleItem
                        ProductImage="20W50-1L.png"
                        ProductImage2="20W50-4L.png"
                        ProductImage3="20W50-5L.webp"
                        ProductImage4="20W50-20L.png"
                        ProductCategory="Gasoline/Diesel Engine"
                        ProductName="<span> 20W50 </span> Golden power Heavy duty"
                        ProductApi="API SL/CI-4"
                        ProductDatail="A very high-quality mineral engine oil for most 4-stroke enginesProvides very good resistance to oxidation and has optimal detergent and dispersion properties."
                        ProductSize1="1L"
                        ProductSize2="4L"
                        ProductSize3="5L"
                        ProductSize4="20L"
                        RouteLink="/20w50"
                      />
                    </div>
                  </div>
                </div>

                {/* Engine Flush OIL IF NO CHECK BOX IS SELECTED */}

                <div className="Gear" id="Flushoil">
                  <div className="ProductHeding">
                    <Text
                      text="Engine Flush"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">
                      {/* PRODUCT 9  ENGINE FLUSH  */}

                      <ProdtuctPageSingleItem
                        ProductImage="ENGINE FLUSH-4L.webp"
                        ProductImage2="ENGINE FLUSH-4L.webp"
                        ProductImage3="ENGINE FLUSH-4L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> ENGINE FLUSH </span>"
                        ProductDatail="Engine Flush is a specially formulated combination of additives and a carrier fluidIn combination with the carrier fluid, the highly effective detergent and dispersant additives dissolve sludge and lacquer formers."
                        ProductSize1="4L"
                        RouteLink="/enginflush"
                      />
                    </div>


                  </div>
                </div>

                {/* Gear OIL IF NO CHECK BOX IS SELECTED */}

                <div className="Gear" id="gearoil">
                  <div className="ProductHeding">
                    <Text
                      text="Gear Oils"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems ">
                    <div className="ProductItemsEach">
                      {/* PRODUCT 10  ENGINE FLUSH  */}

                      <ProdtuctPageSingleItem
                        ProductImage=" CVT-1L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> CVT Fluid  </span> Full Synthetic"
                        ProductDatail="Full synthetic fluid designed for most CVT (Continuously Variable Transmission) gearboxes with steel push-belts or chainsIt offers superior anti-wear protection and stable friction characteristics."
                        ProductSize1="1L"
                        RouteLink="/cvtoil"
                      />
                    </div>

                    <div className="ProductItemsEach sae90">
                      {/* PRODUCT 11  SAE 90  */}

                      <ProdtuctPageSingleItem
                        ProductImage=" GEAR OIL-1L.webp"
                        ProductImage2=" GEAR OIL-18L.png"
                        ProductCategory="Gear Oils"
                        ProductName="<span> SAE 90  </span>"
                        ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a 'limited-slip' mechanism providing superior protection against wear and rust."
                        ProductSize1="1L"
                        ProductSize2="18L"
                        RouteLink="/sae90"
                      />
                    </div>
                    <div className="ProductItemsEach sae90">
                      {/* PRODUCT 12  SAE 90  */}

                      <ProdtuctPageSingleItem
                        ProductImage=" Gear oil-Sae140-18L.png"
                        ProductCategory="Gear Oils"
                        ProductName="<span> SAE 140  </span>"
                        ProductDatail="SAE 140 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a 'limited-slip' mechanism providing superior protection against wear and rust."
                        // ProductSize1="1L"
                        ProductSize1="18L"
                        RouteLink="/sae140"
                      />
                    </div>

                    <div className="ProductItemsEach sae90">
                      {/* PRODUCT 12  SAE 90  */}

                      <ProdtuctPageSingleItem
                        ProductImage=" Gear oil-Sae140-1L.png"
                        ProductCategory="Gear Oils"
                        ProductName="<span> SAE 85W-90  </span>"
                        ProductDatail="SAE 85W-90 gear oil is a premium quality lubricant designed for use in differentials, which are equipped with a ‘limited-slip’ mechanism providing superior protect against wear and rust."
                        // ProductSize1="1L"
                        ProductSize1="1L"
                        RouteLink="/sae85w90"
                      />
                    </div>


                  </div>
                </div>

                {/* Transmission OIL IF NO CHECK BOX IS SELECTED */}

                <div className="Gear" id="Transmissionoil">
                  <div className="ProductHeding">
                    <Text
                      text="Transmission Oils"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">

                      {/* PRODUCT 13  DEXRON III  */}

                      <ProdtuctPageSingleItem
                        ProductImage="DEXRON-III-1L.webp"
                        ProductImage2="DEXRON-III-1L.webp"
                        ProductImage3="DEXRON-III-1L.webp"
                        ProductCategory="Diesel Engine"
                        ProductName="<span> DEXRON III  </span> Automatic Plus Transmission fluid"
                        ProductDatail="This ATF Automatic Transmission Fluid has a high viscosity index to ensure great fluidity on engine start and guaranteed component protection at high temperatures."
                        ProductSize1="1L"
                        RouteLink="/dexron"
                      />
                    </div>
                  </div>
                </div>

                {/* Hydraulic OIL IF NO CHECK BOX IS SELECTED */}
                  <div className="Gear" id="Hydraulicoil">
                  <div className="ProductHeding">
                    <Text
                      text="Hydraulic Oils"
                      Color="#283989"
                      FontSize="2rem"
                      FontWeight="600"
                    />
                  </div>
                  <div className="ProductItems">
                    <div className="ProductItemsEach">

                      {/* PRODUCT 13  DEXRON III  */}

                      <ProdtuctPageSingleItem
                        ProductImage="HydraulicHy68.png"
                        ProductCategory="Hydraulic Oils"
                        ProductName="<span> HY 68  </span> Fleet X"
                        ProductDatail="Hydraulic 68 Oils is a premium quality lubricant designed for use in differentials, which are equipped with a ‘limited-slip’ mechanism providing superior protect against wear and rust."
                        ProductSize1="18L"
                        RouteLink="/hy68"
                      />
                    </div>
                  </div>
                </div>

                
              </>
            )}
        </div>
      </div>

      {/************************* 
         * SECTION 3
        FOOTER
       * ***************************** */}
      <Footer Src1=" gmail.svg" Src2=" facebook.svg" Src3=" instagram.svg" />
    </div>
  );
}
