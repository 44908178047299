import React, { useRef } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Image from '../../Components/Image/Image'
import "../../Styles/ContactUs.css"
import Footer from '../../Components/Footer/Footer'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PageDetail from '../../Components/PageDetail/PageDetail'
import MyButton from '../../Components/Button/Button'
import emailjs from "@emailjs/browser";

export default function ContactUs() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6p0x7m5",
        "template_lj6nva5",
        form.current,
        "4JQe4OH0Ax0hlH1yu"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Email sent successfully!');
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          toast.error('An error occurred while sending the email.');
        }
      );
  };
    

  return (
    <div className='ContactUs'>
        <div className="AboutUsNAv d-flex justify-content-center">
            <Navbar /> 
        </div>

        {/************************* 
         * SECTION 2
        Wide Range Cetagories Section
       * ***************************** */}
        <div className="ContactUS">
          <PageDetail Color="black"  text="Categories" text1=" WIDE RANGE OF LUBRICANT" text2="SOLUTIONS" text3 ="Company dedicated to providing the highest quality products for all your engine needsOur advanced formulation is specially designed to deliver maximum protection against wear and tear, resulting in a smoother, more efficient engine performanceWe pride ourselves on using only the finest ingredients and adhering to strict manufacturing standards to ensure that every bottle of PUREOIL meets our uncompromising quality standards" />
        </div>

        {/************************* 
         * SECTION 3
        About Section
       * ***************************** */}
        <div className="WriteUs" id='contactform'>
          <PageDetail  text="Write Us" text1=" HAVE SOMETHING  <br> IN YOUR" text2="MIND?" text3 ="Our customer service representatives are available Monday through Friday from 9am to 5pm to assist you with any inquiries you may haveWe are committed to providing our customers with the highest level of service and support, and we look forward to hearing from you soon<br> <br >Thank you for choosing PUREOIL." />
        </div>

        {/************************* 
         * SECTION 4
        About Section
       * ***************************** */}  
       <div className="ContactForm" >
        <form ref={form} onSubmit={sendEmail}>
          <div className="formData">
            <div className="username">
              <label for="">your name</label>
              <input type="text" name="user_name" id=""  placeholder='Enter your name' required/>
            </div>
            <div className="useremail">
            <label for="">email</label>
              <input type="email" name="user_email" id=""  placeholder='Enter your email address'  required/>
            </div>
            <div className="usermessage">
            <label for="">message</label>
              <textarea name="message" id="" cols="30" rows="5" placeholder='Your message here...' required></textarea>
            </div>
          </div>
          <div className="submitBtn text-center">
            <button className="press-effect" text="Submit">Submit</button>  
          </div>
        </form>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

       </div>

       {/* google map loacaton */}
       <div className="mapsection">
       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.971036030536!2d55.06489810000001!3d24.933056600000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f0c3345c25381%3A0x93349502bf40105e!2sTechno%20Lube!5e0!3m2!1sen!2sae!4v1686917907920!5m2!1sen!2sae" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>


        {/************************* 
         * SECTION 4
        FOOTER
       * ***************************** */}

          <Footer Src1="gmail.svg" Src2="facebook.svg" Src3="instagram.svg" />


    </div>
  )
}
