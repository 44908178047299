import React, { useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import "../../Styles/Landing.css"
import Text from '../../Components/Text/Text'
import Image from '../../Components/Image/Image'
import { Link } from 'react-router-dom'
import PageDetail from '../../Components/PageDetail/PageDetail'
import VerticalBorderText from '../../Components/VerticalBorderText/VerticalBorderText'
import { HashLink } from 'react-router-hash-link';



import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import EachProduct from '../../Components/EachProduct/EachProduct'
import Footer from '../../Components/Footer/Footer'
import Preloader from '../../Components/Preloader/Preloader'



export default function Landing() {

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);


  // const SwiperHeader = () => {
  //   return (
  //     <Swiper
  //       modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
  //       spaceBetween={25}
  //       slidesPerView={1}
  //       loop={true}
  //       speed={1500}
  //       autoplay={{
  //         delay: 2000,
  //         disableOnInteraction: false,

  //       }}

  //       // breakpoints={{
  //       //   // Responsive breakpoints
  //       //   450: {
  //       //     slidesPerView: 1.4, // Number of slides to show on mobile
  //       //     spaceBetween: 10, // Space between slides on mobile
  //       //   },
  //       // }}

  //       // pagination={{
  //       //   type: "",
  //       //   spaceBetween: 70,
  //       // }}



  //       onSlideChange={() => console.log("slide change")}
  //       onSwiper={(swiper) => console.log(swiper)}
  //     >
  //       <SwiperSlide>
  //       <SwiperSlide>
  //         <div className="LandingPageHeader1">
  //         <div className="MobheaderSlider2">
  //             <Image Src=" headerImg1.webp" Width="76%" />
  //           </div>
  //           <div className="HeaderText">
  //             <Text text="pureoil lubricants" FontSize="2rem" TextTransform="uppercase" />
  //             <Text text="speed <br> performance <br> excellence" FontSize="5rem" TextTransform="uppercase" FontWeight="500" Margin="8px 0px 0px 0px" Color="yellow" />
  //             <Text text="All in the one solution for your engine oil" FontSize="2rem" TextTransform="uppercase" Margin="8px 0px 0px 0px" />
  //           </div>
  //           <div className="headerSlider2">
  //             <Image Src=" headerImg1.webp" Width="76%" />
  //           </div>
  //         </div>
  //       </SwiperSlide>
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <div className="LandingPageHeader2">
  //         <div className="MobheaderSlider2">
  //             <Image Src=" headerSlider2.png" />
  //           </div>
  //           <div className="HeaderText">
  //             <Text text="pureoil lubricants" FontSize="2rem" TextTransform="uppercase" />
  //             <Text text="speed <br> performance <br> excellence" FontSize="5rem" TextTransform="uppercase" FontWeight="500" Margin="8px 0px 0px 0px" Color="yellow" />
  //             <Text text="All in the one solution for your engine oil" FontSize="2rem" TextTransform="uppercase" Margin="8px 0px 0px 0px" />
  //           </div>
  //           <div className="headerSlider2">
  //             <Image Src=" headerSlider2.png" />
  //           </div>
  //         </div>
  //       </SwiperSlide>
  //       <style>
  //         {`.swiper-pagination{
  //         color: red;
  //       },
  //       .swiper-pagination-bullet {
  //         color: red;
  //         background-color: red;
  //       }`}
  //       </style>
  //     </Swiper>
  //   );
  // };


  const SwiperCarousel = () => {
    return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={25}
        slidesPerView={2.4}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.1, // Number of slides to show on mobile
            spaceBetween: 15, // Space between slides on mobile
          },
          820: {
            slidesPerView: 2.4, // Number of slides to show on mobile
            spaceBetween: 15, // Space between slides on mobile
          }

        }}

        pagination={{
          type: "bullets",
          spaceBetween: 70,
        }}



        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}

      >
        <SwiperSlide>
          <EachProduct RouteLink="/0w20" Src=" 0W20-5L.webp " ProductCategory="Gasoline Engine" ProductName="<span>0W-20</span> Hybrid Synthetic" ProductApi="API SP" ProductDatail="Recommended for modern petrol enginesIt provides outstanding engine..<span>cleanliness, wear protection, strong durability, and Fuel Economy.</span> " />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink="/10w40" Src=" 10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully..<span>selected highly refined base oilsThanks to its optimum viscosity index it can be used all year round.</span>" />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink="/15w40" Src=" 15W40-5L..webp" ProductCategory="Gasoline Engine" ProductName="<span> 15W40 </span> Speed X" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that..<span>delivers reliableperformance in a broad range of heavy-duty applications.</span>  " />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink="/20w50" Src=" 20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty" ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines...."  />
        </SwiperSlide>
        
        <style>
          {`.swiper-pagination{
          color: red;
        },
        .swiper-pagination-bullet {
          color: red;
          background-color: red;
        }
        .swiper{
          height: 60vh !important;
        }`}
        </style>
      </Swiper>
    );
  };
  


  return (
    <>
     {showPreloader ? (
      <Preloader />
    ) : (
    <div className='LandingPage'>

{/************************* 
 * SECTION 1
  Landing Page Header Section
 * ***************************** */}
<div className="hederSec">
  <div className="LandinNavbarWraper">
    <Navbar />
  </div>
  <div className="LandingPageHeader1">
          
            {/* <div className="HeaderText">
              <Text text="pureoil lubricants" FontSize="2rem" TextTransform="uppercase" />
              <Text text="speed <br> performance <br> excellence" FontSize="5rem" TextTransform="uppercase" FontWeight="500" Margin="8px 0px 0px 0px" Color="yellow" />
              <Text text="All in the one solution for your engine oil" FontSize="2rem" TextTransform="uppercase" Margin="8px 0px 0px 0px" />
            </div> */}
            <div className="headerSlider2">
              <Image Src=" headerimg.png" Width="85%" />
            </div>
          </div>
</div>


{/************************* 
   * SECTION 2
  Products Cetagories
 * ***************************** */}
<div className="productCetagory">
  <div className="row m-0 p-0 d-flex justify-content-center">
    <div className="col-md-3 col-4 text-center">
      <div className="categoryEach  d-flex justify-content-center align-items-center">
        <HashLink smooth to="/product#gasoline">
          <Image Src="petrolCategory.webp" />
          <Text text="Gasoline <br> engine <br> oil " Color="#273889" FontSize="2rem" FontWeight="700" Margin="0px 0px 0px 25px" TextTransform="capitalize" />
        </HashLink>
        <Link>
        </Link>
      </div>
    </div>
    <div className="col-md-3 col-4 text-center">
      <div className="categoryEach d-flex justify-content-center align-items-center">
        <HashLink smooth to="/product#diesel">
          <Image Src=" dieselCategory.webp" />
          <Text text="deisel <br> engine <br> oil " Color="#273889" FontSize="2rem" FontWeight="700" Margin="0px 0px 0px 25px" TextTransform="capitalize" />
        </HashLink>
      </div>
    </div>
    <div className="col-md-3 col-4 text-center">
      <div className="categoryEach d-flex justify-content-center align-items-center" >
        <HashLink smooth to="/product#gearoil">
          <Image Src=" othersCategory.webp" className="d-inline" />
          <Text text="Gear<br>oil " Color="#273889" FontSize="2rem" FontWeight="700" Margin="0px 0px 0px 25px" TextTransform="capitalize" />
        </HashLink>
      </div>
    </div>

  </div>
</div>


{/************************* 
   * SECTION 3
  Wide Range Cetagories Section
 * ***************************** */}
<div className="WideRange">
  <PageDetail text="Categories" text1=" WIDE RANGE OF <br> LUBRICANT" text2="SOLUTIONS" text3="Our wide range of lubricant solutions includes  motorbike oil, car oil, heavy duty oil, and gear oil  - each specially formulated to provide maximum   protection and performance for your engine." />
</div>

{/************************* 
   * SECTION 4
  Products details Section
 * ***************************** */}

<div className="productDetailsSec">
  <div className="row m-0 p-0 d-flex justify-content-between">
    <div className="col-md-5 col-sm-6">
      <VerticalBorderText text="Gasoline Engine Oil:" text1="Our gasoline engine oil is specifically designed to meet the needs of modern engines, delivering superior protection against wear and tear, friction, and high temperatures Whether you have a petrol or diesel engine, PUREOIL car  oil provides maximum performance and reliability." Color1="#273889" Color2="black" />
    </div>
    <div className="col-md-5 col-sm-6">
      <div className="rightSec">
        <Image Src=" Gasoline.webp" Width="100%" />
      </div>
    </div>
  </div>

  {/* 2nd Row */}

  <div className="DieselEngineOil row m-0 p-0 d-flex justify-content-between">
    <div className="col-md-5 col-sm-6">
      <div className="rightSec">
        <Image Src=" Diesel.webp" Width="100%" />
      </div>
    </div>
    <div className="col-md-5 col-sm-6">
      <VerticalBorderText text="Diesel Engine Oil:" text1="For diesel engine oil such as commercial vehicles, construction equipment, and agricultural machinery, PUREOIL heavy-duty oil is the ideal lubricant solutionOur advanced formulation provides maximum protection against extreme temperatures, high loads, and other challenging conditions." Color1="#273889" Color2="black" />
    </div>
    <div className="MobImage">
        <Image Src=" Diesel.webp" Width="100%" />
      </div>
  </div>

  {/* 3rd row */}

  <div className="GearOil row m-0 p-0 d-flex justify-content-between">
    <div className="col-md-5 col-sm-6">
      <VerticalBorderText text="Gear Oil:" text1="PUREOIL's gear oil is the perfect choice for transmissions, differentials, and other gear systemsOur advanced formulation provides superior protection against wear and tear, corrosion, and oxidation, ensuring a longer lifespan for your gears." Color1="#273889" Color2="black" />
    </div>
    <div className="col-md-5 col-sm-6">
      <div className="rightSec">
        <Image Src=" GearOil.webp" Width="100%" />
      </div>
    </div>
  </div>
</div>


{/************************* 
   * SECTION 5
  Best Product  Section
 * ***************************** */}

<div className="bestProductClass">
  <div className="row m-0 p-0" >
    <div className="col-md-5">
      <div className='leftSec'>
        <div className="BestProductHead">
          <Text text=" <span> Products </span> <br> best-in-class <br>
            lubricant <span> products </span>" />
          <div className="border"></div>
        </div>

        <Text text="Our products are specially formulated to deliver  maximum protection and performance, helping  to extend the lifespan of your engine while  reducing maintenance costs." />
      </div>
    </div>
    <div className="col-md-7">
      <div className="RightSec">
        <Image Src=" bestProduct.webp" />
      </div>
    </div>
  </div>
</div>

{/************************* 
   * SECTION 6
  Best Product  Section
 * ***************************** */}

<div className="bestSellerSlider">
  <div className="sliderHead">
    <Text text="Best Seller" Color="black" FontWeight="400" FontSize="3rem" />
  </div>
  <SwiperCarousel />
</div>

{/************************* 
   * SECTION 7
  About Section
 * ***************************** */}
<div className="AboutSec">
  <PageDetail text="About" text1=" WE MAKE OIL  <br> TO YOUR" text2="COMFORT" text3="At PUREOIL, we believe in quality above all elseThat's why we use only the finest ingredients in our lubricant and adhere to the strictest manufacturing standardsOur commitment to excellence is reflected in every bottle of PUREOIL - try it today and experience the difference for yourself!" />
</div>


{/************************* 
   * SECTION 8
  FOOTER
 * ***************************** */}
<div className="LandingFooter">
  
<Footer Color="black" Src1=" Hemail.svg" Src2=" Hfacebook.svg" Src3=" Hinstagram.svg" />
</div>

</div>
    )}
    </>



    
  )
}