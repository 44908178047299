import React, { useState } from 'react'
import "../SingleProduct/SingleProduct.css"
import { Link } from 'react-router-dom'


import Image from '../Image/Image'
import Text from '../Text/Text'




export default function ProductPageSingleItem({ ProductImage, ProductImage2,ProductImage3 ,ProductImage4, ProductCategory, ProductName, ProductApi, ProductDatail, ProductSize1, ProductSize2, ProductSize3, ProductSize4,RouteLink,

}) {


  const [activePic, setActivePic] = useState('Pic1');

  const handleButtonClicks = (divId) => {
    setActivePic(divId);
  };

  return (
    <>
      <div className='SingleProduct'>
        <div className="slideritems d-flex">
          <div className="sliderimage">

            <div>

              {activePic === 'Pic1' && <div id="Pic1">
                <Image Src={ProductImage} Width="350px" />
              </div>}
              {activePic === 'Pic2' && <div id="Pic2">
                <Image Src={ProductImage2} Width="350px" />
              </div>}
              {activePic === 'Pic3' && <div id="Pic3">
                <Image Src={ProductImage3} Width="350px" />
              </div>}
              {activePic === 'Pic4' && <div id="Pic4">
                <Image Src={ProductImage4} Width="350px" />
              </div>}

              <style>
                {`
          button {
            width: auto;
            height: auto;
            border: none;
            border-radius: 0;
            margin: 0px 5px 0px 5px;
            padding: 10px 15px;
            font-size: 2rem;
            text-transform: none;
            color: black;
            font-weight: 400;
            background-color: white;
          }
          
          .active {
            background-color: #273889;
            color: white;
          }
        `}
              </style>
            </div>
          </div>
          <div className="productdetail">
            <Text text={ProductCategory} Color="#A9A9A9" FontSize="2.5rem" />
            <Text text={ProductName} Color="black" FontSize="3.5rem" />
            {
              ProductApi ?
                <Text text={ProductApi} Color="black" FontSize="2.5rem" />
                :
                ""
            }
            <Text text={ProductDatail} Color="black" Margin="10px 0px 0px 0px" />
            <div className="Specification">
              <Text text="Size Guide" Color="#A9A9A9" Margin="10px 0px 0px 0px" />
              <div className="liters d-flex">

                {
                  ProductSize1 ?
                    <>
                      <button
                        className={activePic === 'Pic1' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic1')}
                      >
                        {ProductSize1}
                      </button>
                      {/* <Text text={ProductSize1} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </>
                    :
                    ""
                }
                {

                  ProductSize2 ?
                    <>
                      <button
                        className={activePic === 'Pic2' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic2')}
                      >
                        {ProductSize2}
                      </button>
                      {/* <Text text={ProductSize2} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </>


                    :
                    ""
                }
                {
                  ProductSize3 ?
                    <>
                      <button
                        className={activePic === 'Pic3' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic3')}
                      >
                        {ProductSize3}
                      </button>
                      {/* <Text text={ProductSize3} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </> :
                    ""
                }
                {
                  ProductSize4 ?
                    <>
                      <button
                        className={activePic === 'Pic4' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic4')}
                      >
                        {ProductSize4}
                      </button>
                      {/* <Text text={ProductSize3} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </> :
                    ""
                }
              </div>
              <div className="Gobutton text-end">
                <Link to={RouteLink}>
                  <i>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                  </i>
                </Link>
              </div>
            </div>
            
          </div>
        </div>
        
      </div>




    </>


  )
}