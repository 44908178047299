import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil10W30() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="10W30-1L.png"
             ProductImage2="10W30-4L.png"
             ProductImage3="10W30-5L.png"
            ProductCategory="Gasoline/Diesel Engine"
            ProductName="<span> 10W-30 </span> GOLDEN POWER"
            ProductApi="API SL/CF"
            ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oilsThis exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
            ProductSize1="1L"
            ProductSize2="4L"
            ProductSize3="5L"

            // PRODUCT INFORMATION

            productInfo1= "This oil is perfectly suited for a wide range of gasoline and diesel engines, including both naturally aspirated and supercharged variantsIt offers exceptional fluidity at low temperatures and maintains a stable viscosity even under high temperature conditions"

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs"
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist"
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.8488"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="11.5"

            R4td1="Viscosity Index    "
            R4td2="ASTM D 2270   "
            R4td3="155"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -36"

            R6td1="Total Base Number, mg KOH/g"
            R6td2="ASTM D 2896 "
            R6td3="8.50"

            R7td1="Apparent Viscosity @ -35DC, cP"
            R7td2="ASTM D 5293 "
            R7td3="3800"
            
            // RELATED PRODUCTS

            SliderProductCategory1="Diesel Engine"
            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1="HD50-5L.webp"
            SliderProductImage2="20W50-5L.webp"
            SliderProductImage3="10W40-5L.webp"
            SliderProductImage4="15W40-5L..webp"

            SliderProductName1="<span> HD 50 </span> Fleet X"
            SliderProductName2="<span> 20W50 </span> Golden power Heavy duty"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1="API CF"
            SliderProductApi2="API SL/CF"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="HD 50 is performance heavy-duty engine oil, primarily.."
            SliderProductDatail2="A very high-quality mineral engine oil for most 4-stroke..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/hd50"
            SliderRouteLink2="/20w50"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="gmail.svg" Src2="facebook.svg" Src3="instagram.svg" />        
    </div>
  )
}
