import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function OilHY68() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
             ProductImage="HydraulicHy68.png"
            ProductCategory="Hydraulic Oils"
            ProductName="<span> HY 68  </span> Fleet X"
            ProductDatail="Our Hydraulic oil is the lifeblood of hydraulic systems, serving as a crucial component that enables the smooth and efficient operation of heavy machinery, industrial equipment, and various hydraulic applications. "
            ProductSize1="18L"


            // PRODUCT INFORMATION

            productInfo1= "PureOil’s HY is a hydraulic oil with NSF-H1 approval, especially developed to meet the special requirements of the food and beverage industries. It is based on synthetic oils and contain carefully selected additives to prevent wear, oxidation and corrosion. PureOil HY is suitable for a wide range of applications in the food and beverage industries where there may be exposure of the lubricated part to the edible product or its packaging. Particularly for hydraulic systems in production, filling and packaging machines. "

            // HOW TO USE

            textuse1= "Consult your machine owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the machine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil "
            textuse4="Pour the PURE OIL hydraulic oil into the hydraulic oil compartment."
            textuse5="Check the oil level with the dipstick and adjust it if necessary."
            textuse6="Start the machine and let it run for a few minutes before checking for leaks"

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

               // Specifications
            R1td1="Appearance "
            R1td2="Visual"
            R1td3="C & B"

            R2td1="Density @29.5 °C, g/ml"
            R2td2="ASTM D 1298"
            R2td3="0.896"

            R3td1="Kinematic viscosity @100°C, cSt "
            R3td2="ASTM D 445   "
            R3td3="20.8"

            R4td1="Viscosity Index    "
            R4td2="ASTM D 2270   "
            R4td3="96"

            R5td1="Pour Point, °C  "
            R5td2="ASTM D 97"
            R5td3=" -18"

            R6td1="Total Base Number, mg KOH/g"
            R6td2="ASTM D 2896 "
            R6td3="11.50"

            // RELATED PRODUCTS

            SliderProductCategory1="Gasoline/Diesel Engine"
            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1=" 10W30-5L.png"
            SliderProductImage2=" 20W50-5L.webp"
            SliderProductImage3=" 10W40-5L.webp"
            SliderProductImage4=" 15W40-5L..webp"

            SliderProductName1="<span> 10W-30 </span> GOLDEN POWER"
            SliderProductName2="<span> 20W50 </span> Golden power Heavy duty"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1="API SL/CF"
            SliderProductApi2="API SL/CF"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="Introducing a premium semi-synthetic lubricant formulated.."
            SliderProductDatail2="A very high-quality mineral engine oil for most 4-stroke..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/10w30"
            SliderRouteLink2="/20w50"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1=" gmail.svg" Src2=" facebook.svg" Src3=" instagram.svg" />        
    </div>
  )
}
